import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
  activity: [],
  runningWorkflows: {},
  failedWorkflows: {},
  activityTotalNumber: 0,
  activityPage: 1,
  activityLimit: 10,

  // loaders
  getActivityLoading: false,
  getActivityStatLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
