export default {
  ACTIVITY: ({ activity }) => activity,
  RUNNING_WORKFLOWS: ({ runningWorkflows }) => runningWorkflows,
  FAILED_WORKFLOWS: ({ failedWorkflows }) => failedWorkflows,
  ACTIVITY_LOADING: ({ getActivityLoading }) => getActivityLoading,
  ACTIVITY_STAT_LOADING: ({ getActivityStatLoading }) => getActivityStatLoading,
  GET_ACTIVITY_TOTAL_NUMBER: ({ activityTotalNumber }) => activityTotalNumber,
  GET_ACTIVITY_PAGE: ({ activityPage }) => activityPage,
  GET_ACTIVITY_LIMIT: ({ activityLimit }) => activityLimit,
};
