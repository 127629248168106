const mutations = {
    SET_SESSIONS_LIST: (state, payload) => {
        state.sessionsList = payload;
    },
    PUSH_SESSION_LIST: (state, payload) => {
        const list  = state.sessionsList;
        list.push(...payload);
        state.sessionsList = list;
    },
    SET_SESSIONS_LIST_LOADING: (state, payload) => {
        state.sessionsListLoading = payload;
    },
    SET_TOOL_SETS: (state, payload) => {
        state.toolSets = payload;
    },
    SET_TOOL_SETS_LOADING: (state,payload) => {
        state.toolSetsLoading = payload;
    },
    SET_CREATE_TOOL_SET_LOADING: (state,payload) =>{
        state.createToolSetLoading = payload;
    },
    SET_CURRENT_TOOL_SET: (state, payload) => {
        state.currentToolSet = payload;
    },
    SET_CURRENT_TOOL_SET_LOADING: (state, payload) => {
        state.currentToolSetLoading = payload;
    },
    SET_CURRENT_SESSION: (state, payload) => {
        state.currentSession = payload;
        console.log(payload)
    },
    SET_CURRENT_SESSION_LOADING: (state, payload) => {
        state.currentSessionLoading = payload;
    },
    SET_AI_RESPONSE_LOADING: (state, payload) => {
        state.AIResponseLoading = payload;
    },
    SET_USER_SETTINGS: (state, payload) => {
        state.userSettings = payload;
    },
    SET_USER_SETTINGS_LOADING: (state, payload) => {
        state.userSettingsLoading = payload
    },
    SET_SYSTEM_PROMPTS: (state, payload) => {
        state.systemPrompts = payload;
    },
    SET_SYSTEM_PROMPT_LOADING: (state, payload) => {
        state.systemPromptLoading = payload;
    },
    SET_UPDATE_SYSTEM_PROMPT_LOADING: (state, payload) => {
        state.updateSystemPromptLoading = payload;
    },
    SET_SEND_MESSAGE_LOADING: (state, payload) => {
        state.sendMessageLoading = payload;
    },
    CHANGE_SESSION_NAME: (state, payload) => {
        const sessionIndex = state.sessionsList.findIndex((session) => session.id === payload.id);
        state.sessionsList[sessionIndex].name = payload.name;
    },
    SET_EDIT_SESSION: (state, payload) => {
        state.editSession = payload;
    },
    SET_EDIT_SYSTEM_PROMPT: (state, payload) => {
        state.editSystemPrompt = payload;
    },
    SET_EDIT_TOOL_SET: (state, payload) => {
        state.editToolSet = payload;
    }
}

export default mutations