const getters = {
    SESSIONS_LIST: ({ sessionsList }) => sessionsList || [],
    SESSIONS_LIST_LOADING: ({ sessionsListLoading }) => sessionsListLoading,
    TOOL_SETS: ({ toolSets }) => toolSets || [],
    TOOL_SETS_LOADING: ({ toolSetsLoading }) => toolSetsLoading,
    CREATE_TOOL_SET_LOADING: ({ createToolSetLoading }) => createToolSetLoading,
    CURRENT_TOOL_SETS: ({ currentToolSet }) => currentToolSet || {},
    CURRENT_TOOL_SET_LOADING: ({ currentToolSetLoading }) => currentToolSetLoading,
    AI_RESPONSE_LOADING: ({ AIResponseLoading }) => AIResponseLoading,
    CURRENT_SESSION: ({ currentSession }) => currentSession || {},
    CURRENT_SESSION_LOADING: ({ currentSessionLoading }) => currentSessionLoading,
    USER_SETTINGS: ({ userSettings }) => userSettings || {},
    USER_SETTINGS_LOADING: ({ userSettingsLoading }) => userSettingsLoading,
    SYSTEM_PROMPTS: ({ systemPrompts }) => systemPrompts || [],
    SYSTEM_PROMPT_LOADING: ({ systemPromptLoading }) => systemPromptLoading,
    UPDATE_SYSTEM_PROMPT_LOADING: ({ updateSystemPromptsLoading }) => updateSystemPromptsLoading,
    SEND_MESSAGE_LOADING: ({ sendMessageLoading }) => sendMessageLoading,
    GET_EDIT_SESSION: ({ editSession }) => editSession || {},
    GET_EDIT_SYSTEM_PROMPT: ({ editSystemPrompt }) => editSystemPrompt || {},
    GET_EDIT_TOOL_SET: ({ editToolSet }) => editToolSet || {}
}

export default getters